<template>
  <div>
    <PageTitle title="检查报告" fixed backTo="/home"></PageTitle>
    <List style="margin-top: 1rem">
      <div style="text-align: center; width: 100%">
        <van-button
          type="primary"
          name="buttonXF"
          @click="camerastart"
          style="width: 140px"
          >扫描条形码</van-button
        >
        <van-button
          type="primary"
          name="buttonCZ"
          style="margin-left: 20px; width: 140px"
          @click="SRTXM"
        >
          手动输入条形码
        </van-button>
      </div>
      <van-dialog
        v-model="tcs"
        show-cancel-button
        style="color: #3f9775; border-radius: 8px"
        confirm-button-text="取消"
        cancel-button-text="确定"
        @cancel="CS"
        @confirm="GB"
      >
        <p style="text-align: center; margin-top: 10px">手动输入条形码</p>
        <input
          style="
            background: #ffffff;
            width: 260px;
            height: 30px;
            border: 1.21px solid #3f9775;
            box-shadow: inset 0px 0px 5px 0px rgba(119, 119, 119, 1);
            font-size: 12px;
            margin-left: 20px;
            padding-left: 2px;
          "
          oninput="this.value=this.value.replace(/\D/g,'')"
          type="number"
          pattern="[0-9]*"
          id="TSK"
        />
        <div style="height: 10px"></div>
      </van-dialog>
    </List>
    <!--<van-popup v-model="show" @opened="camerastart" @close="closeCamera">-->
    <!--<video id="camera" autoplay width="300" height="485"></video>-->
    <!--<canvas id="canvas" width="800" height="800" hidden="hidden"></canvas>-->
    <!--<img id="Tagimg" style="display:none" src="" alt=""/>-->
    <!--</van-popup>-->
    <div style="position: relative; width: 85%; margin: 15px auto auto auto">
      <List :width="100">
        <ListItem
          v-for="(item, index) in items"
          :key="index"
          :to="
            ba(
              item.exam_order_name,
              item.brxm,
              item.exam_time.replace('T', ' '),
              item.exam_rpt_flow,
              item.judge,
              item.sampleno,
              item.patient_sn
            )
          "
        >
          <template #top>
            <div style="height: 5px"></div>
            <div class="borderColor">
              <div class="leftArea">
                <p class="fontmd">检查项目：{{ item.exam_order_name }}</p>
                <p class="fontsm">姓名：{{ item.brxm }}</p>
                <p class="fontsm">
                  检查时间：{{
                    item.exam_time.replace("T", " ")
                      ? item.exam_time.replace("T", " ")
                      : ""
                  }}
                </p>
              </div>
            </div>
          </template>
        </ListItem>

        <div class="bottomAdd" style="text-align: center">
          <p>注:目前仅提供两年内检验、超声、放射的报告查询</p>
        </div>
      </List>
    </div>
    <van-loading size="24px" vertical v-if="showLoading" class="loading"
      >加载中...</van-loading
    >
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import List from '@/components/home-page/List.vue'
import ListItem from '@/components/home-page/ListItem.vue'
import { GetTestList } from '@/api/check.js'
import { initPagination, loadPageData } from '@/utils/pagination.js'
import { getDefaultAccount } from '@/api/user.js'
export default {
  components: {
    PageTitle,
    List,
    ListItem
  },
  data () {
    return {
      items: [],
      gettime: '', //当前时间
      show: false,
      getimgsetInterval: undefined,
      videoobj: null, //视频对象(全局)
      tcs: false,
      value: '',
      pagination: {
        current: 0,
        pageSize: 20,
        pageCount: 0,
        dataSource: []
      },
      showLoading: true
    }
  },
  created: function () {
    // init sendMsg object
    this.creats()
  },
  mounted () {
    this.itemScroll()
  },
  methods: {
    getTime: function () {
      var _this = this
      _this.gettime = this.$util.moment().format('YYYY-MM-DD')
      //                _this.gettimes = yys + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      _this.gettimes = this.$util
        .moment()
        .subtract(2, 'years')
        .format('YYYY-MM-DD')
      getDefaultAccount(this.$store.getters.userInfo.UserId).then(
        result => {
          this.ckhm = result.ckhm
          GetTestList(_this.gettimes, _this.gettime, result.ckhm).then(
            resultss => {
              this.showLoading = false
              this.items = initPagination(resultss, this.pagination)
              this.items = this.items.map(item => ({
                brxm: item.brxm,
                exam_order_name: item.exam_order_name,
                exam_rpt_flow: encodeURIComponent(item.exam_rpt_flow),
                exam_time: item.exam_time,
                judge: item.judge,
                sampleno: item.sampleno, patient_sn: item.patient_sn
              }))
            },
            () => { }
          )
        },
        () => { }
      )
    },
    ba (a, b, c, d, e, f, g) {
			console.log(g)
      return '/reportsm/' + encodeURIComponent(a) + '/' + b.replace('?', ' ') + '/' + c + '/' + d + '/' + e + '/' + f + '/' + g
    },
    SRTXM () {
      this.tcs = true
    },
    currentTime () {
      //                setInterval(this.getTime, 500);
      this.getTime()
    },
    creats () {
      this.currentTime()
    },
    CS () {
      let a = document.getElementById('TSK')
      if (a.value != '') {
        this.$router.replace('/reportsm/' + a.value)
      }
    },
    GB () {
      let a = document.getElementById('TSK')
      a.value = ''
    },
    loadPageData,
    itemScroll () {
      let that = this
      window.onscroll = function () {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        if (scrollTop + windowHeight + 50 >= scrollHeight) {
          that.items = that.loadPageData(that.items, that.pagination)
        }
      }
    },
    camerastart () {
      location.href =
        'https://xcx.knjs.net/yyyweb/signature/test.html?openid=' +
        this.$util.string.getUrlParam(window.location.search).openid +
        '&t=' +
        new Date().getTime()
    }
  }
};
</script>

<style scoped="" lang="less">
@import "../style/var.less";
.infoContainer {
  padding-bottom: 10px;
  p {
    margin: 0;
  }
  .borderColor {
    background-color: #f6f6f6;
    border-radius: 8px;
    padding: 10px;
    margin: 0 10px;

    .leftArea {
      width: 100%;
      display: inline-block;
    }
    .rightArea {
      width: 15%;
      display: inline-block;
      vertical-align: top;
      p {
        float: right;
      }
      .van-icon {
        float: right;
        margin-top: 40px;
      }
    }
  }
  .fontmd {
    font-size: @font-size-md;
  }
  .fontsm {
    font-size: @font-size-sm;
  }
}
.bottomAdd {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  background: white;
  p {
    color: @green;
    margin: 0;
    font-size: @font-size-sm;
  }
}
.loading {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: @green;
}
.van-dialog {
  height: 140px;
  border-radius: 0px;
}
.van-dialog__header {
  padding-top: 10px;
}
.van-dialog__footer {
  padding-bottom: 10px;
}
</style>
